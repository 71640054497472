/* eslint-disable camelcase */

export default {
	//My tests
	mytest: 'Mine tester',
	bibliotek: 'Bibliotek',
	createnewtest: 'Opprett ny test',
	started: 'Påbegynt',
	starttest: 'Start test',
	continuetest: 'Fortsett test',
	all: 'Alle',
	distributed: 'Distribuert',
	notdistributed: 'Ikke distribuert',
	notstarted: 'Ikke startet',
	completed: 'Fullført',
	completeplural: 'Fullførte:',
	seeresults: 'Se resultater',
	status: 'Status ',
	testkey: 'Testnøkkel',
	type: 'Type',
	recipient: 'Mottaker',
	actions: 'Handlinger',
	deleatedat: 'Slettet på',
	send: 'Send',
	save: 'Lagre',
	sendcodeemail: 'Send testnøkkel på epost',
	january: 'Januar',
	february: 'Februar',
	march: 'Mars',
	april: 'April',
	may: 'Mai',
	june: 'Juni',
	juli: 'Juli',
	august: 'August',
	september: 'September',
	october: 'Oktober',
	november: 'November',
	december: 'Desember',
	norway: 'Norge',
	sweden: 'Sverige',
	germany: 'Tyskland',
	england: 'Storbritannia',
	nederland: 'Nederland',
	totalnew: 'Nye/Totalt',
	message_testcannotbedeleted:
		'Testen må være minst 3 måneder gammel for å bli slettet.',
	warning_deletetest:
		'Testen vil ikke lenger være tilgjengelig for vurdering. Er du sikker på at du vil fortsette?',
	headerchildtest: 'Testresultater for Dysmate barnetest',
	dynamicheaderfortestresult_child: 'DETALJERTE RESULTATER FOR DYSMATE {0} ',
	handbooktitle:
		'{0} På denne siden finner du nyttige ressurser for bruk av Dysmate',
	dynamicheaderfortestreport_child: 'DETALJERT RAPPORT FOR DYSMATE  {0} ',
	dynamicheaderfortestcandidate_child:
		'DETALJERTE KANDIDATOPPLYSNINGER FOR DYSMATE {0} ',
	dynamicheaderfortestresult_adult: 'DETALJERTE RESULTATER FOR DYSMATE {0} ',
	dynamicheaderfortestreport_adult: 'DETALJERT RAPPORT FOR DYSMATE  {0} ',
	dynamicheaderfortestcandidate_adult:
		'DETALJERTE KANDIDATOPPLYSNINGER FOR DYSMATE {0} ',
	headeradulttest: 'Testresultater for DYSMATE Screeningtest for Voksne',
	showbackground: 'Vis bakgrunnsinformasjon om kandidaten',
	incompleteMessage:
		'Denne testen er ikke komplett, og noen deltester mangler eller er ikke gjennomført enda',
	summary: 'Oppsummering av {0}',
	printtestresults: 'Print testresultater',
	zscorered: 'Z-skåre er mer enn 1 standardavvik {0} gjennomsnittet',
	zscoregreen: 'Z-skåre er mer enn 1 standardavvik {0} gjennomsnittet',
	zscoreblue: 'Z-skåre er innenfor gjennomsnittsområdet',
	overview: 'Oversikt',
	assessment: 'Vurdering: ',
	assessmentspeechok:
		'{0} har oppnådd resultater som ligger i gjennomsnittsområdet eller bedre for eksperimentell Ett Minutt Test, og ser derfor ut til å ha gode leseferdigheter',
	assessmentspeechnotok:
		'{0} har oppnådd resultater som ligger under gjennomsnittsområdet for eksperimentell Ett Minutt Test, og bør derfor utredes videre med tanke på dysleksi og/eller leseforståelsesvansker',
	asessmenttextok_1:
		'{0} har oppnådd resultater som ligger i gjennomsnittsområdet eller bedre for {1} av testene, og ser derfor ut til å ha gode lese- og skriveferdigheter',
	asessmenttextnotok_1:
		'{0} har oppnådd resultater som ligger under gjennomsnittsområdet på {1} av de {2} testene, og bør derfor utredes videre med tanke på dysleksi og/eller leseforståelsesvansker',
	assesmenttextok_youthfollowup:
		'{0} har ingen utslag på de typiske kognitive markørene på dysleksi.',
	assesmentok_recommendation_youthfollowup:
		'Dersom {0} likevel har oppnådd skårer under gjennomsnittsområdet på Leseprøven og/eller Ordforståelse, eller eventuelt på noen av de andre av testene i DYSMATE screeningtest, anbefales en videre kartlegging med tanke på mulig leseforståelsessvikt og/eller mulig underliggende utviklingsmessige språkforstyrrelse (DLD).',
	workingmemorylowassesment:
		'{0} har utslag på Arbeidsminne, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder på denne testen. Arbeidsminne er i seg selv ikke en kognitiv markør på dysleksi, men er assosiert med både dysleksi og leseforståelsessvikt. Redusert arbeidsminne kan blant annet påvirke hvor mye informasjon man greier å få med seg fra lest tekst. Det anbefales derfor å undersøke i hvor stor grad arbeidsminnet til {2} påvirker tilegnelsen av nytt fagstoff, og om det er behov for skolefaglig tilrettelegging rundt dette.',
	rapidnominationlowassesment:
		'{0} har utslag på Hurtigbenevning, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder på denne testen. Hurtigbenevningstesten måler hvor raskt og nøyaktig man greier å hente frem ord fra språkminnet. Svake ferdigheter på dette området er en kognitiv markør på dysleksi.',
	oneminutelowassesment:
		'{0} har utslag på Ettminuttstesten, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder. Ettminuttstesten gir et rent mål på antallriktig leste ord per minutt, og gir et godt bilde av avkodingsferdigheter. Svake avkodingsferdigheter er en markør på dysleksi.',
	oneminutelowassesment2:
		'NB! Det anbefales å vurdere resultatene på Ettminuttstesten i sammenheng med resultatene på Diktatprøven og Ordkjedetesten på DYSMATE screeningtest.',
	spoonerismlowassesment:
		'{0} har utslag på Spoonerism, og oppnår på denne testen en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder. Spoonerism-testen måler fonembevissthet og fonologisk prosesseringshastighet. Svake ferdigheter på disse områdene er en kognitiv markør på dysleksi.',
	fonemutelatelselowassesment:
		'{0} har utslag på Fonemutelatelse, og oppnår på denne testen en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder. Fonemutelatelse-testen måler fonembevissthet og fonologisk prosesseringshastighet. Svake ferdigheter på disse områdene er en kognitiv markør på dysleksi.',

	assessmentokBHtest:
		'{0} har oppnådd resultater som ligger i gjennomsnittsområdet eller bedre for testene, og ser ut til å ligge på det nivå som er typisk for sin alder på områder som er viktig for å utvikle gode lese- og skriveferdigheter.',
	assessmentBHonlyLK:
		'{0} har lavere enn forventet bokstavkunnskap for trinnet. Dette kan ha sammenheng med fremgangsmåten for bokstavinnlæringen på skolen. Det anbefales likevel at man følger nøye med utviklingen i elevens bokstav-lyd-innlæring.',
	assessmentBHRI:
		'{0} har utslag på Hurtigbenevning, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn på denne testen. Hurtigbenevningstesten måler hvor raskt og nøyaktig man greier å hente frem ord fra språkminnet. Svake ferdigheter på dette området er en kognitiv markør på dysleksi',
	assessmentBHWM:
		'{0} har utslag på Arbeidsminne, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn på denne testen. Arbeidsminne er i seg selv ikke en kognitiv markør på dysleksi, men er assosiert med både dysleksi og leseforståelsessvikt. Redusert arbeidsminne kan blant annet påvirke hvor mye informasjon man greier å få med seg fra lest tekst. Det anbefales derfor å undersøke i hvor stor grad arbeidsminnet til {2} påvirker tilegnelsen av nytt fagstoff, og om det er behov for skolefaglig tilrettelegging rundt dette.',
	assessmentBHFI:
		'{0} har utslag på Fonemisolasjon, og oppnår på denne testen en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn. Fonemisolasjonstesten måler fonembevissthet og fonologisk prosesseringshastighet. Svake ferdigheter på dette området er en kognitiv markør på dysleksi.',
	assessmentBHFU:
		'{0} har utslag på Fonemutelatelse, og oppnår på denne testen en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn. Fonemutelatelsestesten måler fonembevissthet og fonologisk prosesseringshastighet. Svake ferdigheter på dette området er en kognitiv markør på dysleksi.',

	rawscore: 'Råskåre',
	zscore: 'Z-Skåre',
	percentilescore: 'Percentilskåre',
	tscore: 'T-Skåre',
	scaledscore: 'Skalert skåre',
	normalrange: 'gjennomsnittsområdet',
	assessmentsubtestnormal: 'Denne skåren er i gjennomsnittsområdet',
	theAverage: 'gjennomsnittet.',
	assessmentsubtestbelow: 'Denne skåren ligger {0} standardavvik {1}',
	under: 'under',
	marginallyunder: 'betydelig under', // zscore -3 <-2.01
	barelyunder: 'klart under', // zscore -2 <-1.01
	marginallyover: 'betydelig over', // zscore +2.01 <+3
	clearlyover: 'klart over', // zscore +1.01 <+2
	within: 'innenfor', // zscore -1 < +1
	assessmentsubtestabove: 'Denne skåren ligger {0} standardavvik {1}',
	over: 'over',
	workingmemorytest: 'Arbeidsminne',
	spoonerismtest: 'Spoonerism',
	fonemutelatelsetest: 'Fonemutelatelse',
	rapidautonamingtest: 'Hurtigbenevning',
	oneminutetest: 'Ettminutttest',
	letterknowledgetest: 'Bokstavkunnskap',
	/* literatePrivacyPolicy: 'Literates personvernerklæring', */
	literatePrivacyPolicy: '',
	confirmConsent:
		'Er denne testen med i normeringsstudien? Hvis dette er en øvingstest utenom normeringsstudien, så trykk "Nei". Det betyr at testen ikke blir medregnet i normeringen.',
	hasChildGrownInMlHome: 'Er barnet vokst opp i et flerspråklig hjem?',
	whichDeviceIsGoingToBeUsed: 'Hvilket utstyr skal testkandidaten bruke?',
	areParentSuspiciousOfdyslexia:
		'Mistenker foreldre eller skolen at barnet har lese- og skrivevansker?',
	hasChildBeenDiagnosed: 'Har barnet fått påvist lese- og skrivevansker',
	classGrade: 'Klassetrinn',
	grade: 'trinn',
	consentText:
		'Testkandiatens foresatte har gitt samtykke til deltakelse i normeringsstudien.',
	constTestYouth:
		'Som Testadministrator så bekrefter du at du har nødvendig samtykke fra Testkandiaten til å innhente personopplysninger og at disse brukes som beskrevet i Literates {0}',
	constTestChild:
		'Som Testadministrator så bekrefter du at du har nødvendig samtykke til å innhente personopplysninger og at disse brukes som beskrevet i dysmates {0}',
	privacyPolicy: 'personvernerklæring',
	iConsent: 'Jeg bekrefter:',
	questionFirstLanguage: 'Hva er barnet 1. språk?',
	questionSecondLanguage: 'Hva er barnet 2. språk?',
	otherFirstLanguage: 'Annet 1. språk',
	otherSecondLanguage: 'Annet 2. språk',
	phonemisolationtest: 'Fonemisolasjon',
	worddictationtest: 'Orddiktat',
	phonemeomissiontest: 'Fonemutelatelse',
	wordchaintest: 'Ordkjedetest',
	nonwordtest: 'Tulleordtesten',
	typingspeedtest: 'Skrivehastighet',
	readingtest: 'Leseprøven',
	wordcomprehensiontest: 'Ordforståelse',
	loggin: 'Logg inn',
	loggout: 'Logg ut',
	users: 'Brukere',
	needsapproval: 'Trenger godkjenning',
	notcompletedregistration: 'Ikke fullført registrering',
	trialperiod: 'Prøveperiode',
	trialuser: 'Prøvebruker',
	basicsupcription: 'Basis abonnement',
	fullsupscription: 'Fullt abonnement',
	childsubscription: 'Barnetest abonnement',
	adultsubscription: 'Vokstentest abonnement',
	youthsubscription: 'Ungdomstest abonnment',
	name: 'Navn: ',
	born: 'Født: ',
	firstname: 'Fornavn',
	lastname: 'Etternavn',
	dateofbirth: 'Fødselsdato: ',
	birthDate: 'Fødselsdato',
	continue: 'Fortsett',
	paneMessageContinueTest:
		'Dette vil sende en ny lenke til testkandidaten for å fortsette testen som ikke ble fullført tidligere. Vil du fortsette?',
	telephone: 'Telefon',
	email: 'E-post',
	edit: 'Endre',
	edituser: 'Endre bruker',
	seesubscription: 'Se abonnement',
	adulttests: 'Voksentester',
	youthtests: 'Ungdomstester',
	confirmationofcost: 'Bekreftelse av kostnad',
	sex: 'Kjønn',
	male: 'Mann',
	female: 'Kvinne',
	education: 'Utdanning',
	from: 'Fra:',
	to: 'Til:',
	loggedIn: 'Innlogget',
	blocked: 'Blokkert',
	blockuser: 'Blokker bruker',
	unblockuser: 'Ublokker bruker',
	country: 'Land:',
	allcountries: 'Alle land',
	cancel: 'Avbryt',
	confirm: 'Bekreft',
	age: 'Alder',
	age_specified: 'Alder (år)',
	testdate: 'Testdato',
	childtest: 'Barnetest',
	experimentalchildtest: 'Eksperimentell Barnetest',
	youthtest: 'Youth-screening',
	adulttest: 'Voksen-screening',
	adultscreening: 'Screeningtest for voksne',
	printedBy: 'Trykt av:  ',
	youthscreening: 'Screeningtest for ungdom',
	childtests: 'Barnetester',
	nousedtests: 'Ingen brukte {0} tester',
	used: 'Brukte:',
	boy: 'Gutt',
	girl: 'Jente',
	logg: 'Hendelseslogg',
	of: 'av {0}',
	wordsperminute: 'ord per minutt',
	message_invalidsubscription:
		'Du har ikke alle gyldige abonnementer som kreves for å lage disse tester',
	deleteoldtests: 'Slett gamle tester',
	trashcan: 'Søppelbøtte',
	addtesttogroup: 'Del i gruppe',
	message_incompletData: 'Ugyldige eller ufullstendige data',
	newclasscreated: 'Ny klasse opprettet',
	testcandidateremoved: 'Testkandidat er fjernet fra Klassen',
	testcandidateadded: 'Testkandidat lagt til klassen',
	classexistchoosename:
		'Klassen finnes allerede, vennligst velg et annet navn.',
	class: '.trinn',

	//Mysubscription page
	subscription: 'Abonnement',
	supersubscription: 'sitt abonnement',
	mysubscription: 'Mitt abonnement',
	seetests: 'Se tester',
	teststhismonths: 'Tester denne måneden',
	coststhismonth: 'Kostnader denne måneden',
	currencysymbol: 'NOK',
	hideWithArrow: 'Skjul ▲',
	showearliermonths: 'Se tidligere måneder ▼',
	previousperiods: 'Tidligere perioder',
	period: 'Periode',
	tests: 'Tester',
	statuses: 'Statuser',
	costs: 'Konstander',
	yourtrialperiodhasexpired: 'Din prøveperiode har utløpt',
	upgradeyoursubscription: 'Oppgrader ditt abonnement',
	downloadandsendcontract:
		'For å ta flere tester må du tegne et abonnement. Gå inn på «Min side» på literate.no for å bestille abonnement, eller last ned kontrakt og send til ',
	priceandconditioninfo: '. Prisinformasjon og betingelser finnes på ',
	downloadcontract: 'Last Ned Kontrakt',
	onlytwotestspertrialperiod: 'Kun 2 tester er tilgjengelige i prøveperioden',
	upgradetofullsubscription:
		'oppgrader til fullt abonnement for å opprette nye tester',
	fetch: 'Gjenopprett',
	language: 'Språk',
	child: 'Barne',
	singleChild: 'Barn',
	selectedTestAddedToGroup: 'Valgte {0} lagt til i gruppen',
	selectedTestRemovedFromGroup: 'Valgte {0} fjernet fra grupper',
	youth: 'Ungdom',
	adult: 'Voksen',
	youthfollowup: 'Ungdom-Oppfølging',
	youthfollowuptest: 'Ungdom-Oppfølgingstest',
	adultfollowup: 'Voksen-Oppfølging',
	adultfollowuptest: 'Voksen-Oppfølgingstest',
	childfollowuptest: 'Barn-Oppfølgingstest',
	childnorming: 'Barne-Normering',
	youthnorming: 'Ungdom-Normering',
	adultnorming: 'Voksen-Normering',
	deletedon: 'Slettet på',
	basic: 'Basic',
	deletedtests: 'Slettede tester',
	viewsubscription: 'Se abonnement ▼',
	viewdeleted: 'Slettede tester ▼',
	referalinformation: 'sin informasjon',
	referalgroups: 'sine grupper',
	mygroups: 'Mine grupper',
	myinformation: 'Mine opplysninger',

	//NewTestModel
	message_costsforeachtestthatis_started:
		'Det vil påløpe kostnader for hver test som blir tatt i bruk. Tester som ikke startes vil heller ikke bli fakturert for.',
	test_type: 'Test type',
	screeningtest_adult: 'Screeningtest (voksen)',
	numberoftests: 'Antall tester (maks 60)',
	create_tests: 'Opprett nye tester',
	//Login
	loginwithbankid:
		'Logg inn med Feide eller BankID ved å trykke på en av knappene under',
	message_thisisforcertifieduser:
		'Dette er for Testadministrator (dvs. sertifiserte brukere med abonnement) ',
	bankid: 'BankID',
	bankidonmobile: 'BankID på Mobil',
	//TestadminModel
	edittestadministrator: 'Endre testadministrator',
	personalinformation: 'Personalia',
	shcool_or_company: 'Skole/bedrift',
	addanewtestadministrator: 'Legg til en ny testadministrator',
	addtestadministrator: 'Legg til testadministrator',
	saveuserinformation: 'Lagre kandidatopplysninger',
	delete: 'Slett',
	deleteuser: 'Slett bruker',
	currentstatus: 'Nåværende status: ',
	savesubscription: 'Lagre abonnement',
	organization: 'Organisasjon',
	//constants/Users
	fullname: 'Fullstendig Navn',
	needsconfirmation: 'Trenger godkjenning',
	hasnotcompletedregistration: 'Ikke fullført registrering',
	//fullsupscription:"Fullt abonnement",
	fullsubscriptionadulttests: 'Fullt abonnement (voksentester)',
	fullsubscriptionayouthtests: 'Fullt abonnement (ungdomstester)',
	fullsubscriptionachildrentests: 'Fullt abonnement (barnetester)',
	inactive: 'Inaktiv',
	message_nouserswiththestatus: 'Ingen brukere med status {0}',
	message_confirmation:
		'Dette vil sende en bekreftelse til brukerens epost, og gi brukeren tilgang til å begynne prøveperiode.',

	//Registration
	createnewuser: 'Lag ny bruker',
	success: 'Suksess!',
	newuser: 'Ny Bruker',
	newloginmethod: 'Ny Innloggingsmetode',
	errorhasoccured: 'En feil har oppstått',
	message_singleuser_perks_trial:
		'Med en bruker kan du prøve tjenesten gratis i 30 dager og får med 2 gratis tester',
	message_change_loginmethod:
		'Du er i ferd med å endre påloggingsmetoden. Husk å logge på med samme legitimasjon neste gang',
	info_for_professional_with_either_completed_screening_test:
		'Dette er for fagpersoner som enten har gjennomført, eller som har meldt seg på, sertifiseringskurs og som ønsker å prøve Dysmate screeningtest før man tegner fullt abonnement.',
	info_prerequisites_to_obtain_license:
		'For å få prøvelisens må man ha gjennomført eller meldt seg på Dysmate sertifiseringskurs. Alle registreringer godkjennes manuelt, og ved godkjenning sendes en e-post med informasjon for å fullføre registreringen',
	//Alltests & single child test
	removefiltering: 'Fjern filtering',
	printreport: 'Lagre eller print testrapport',
	printwithcomment: 'Lagre eller print {0}',
	printingWarning:
		'Vennligst avstå fra å laste ned eller skrive ut resultatene. Vil du fortsatt skrive ut uansett?',
	print: 'Print {0}',
	testresult: 'testresultater',
	testreport: 'testrapport',
	hide: 'Skjul ',
	show: 'Vis ',
	userinformation: 'Kandidatopplysninger',
	localizedwriteuptextforNormalresults:
		'{0} oppnår resultater som er {1} {2} {3} gjennomsnittsområdet på {4} av deltestene. Dette indikerer at {5} har gode lese- og skriveferdigheter.',
	localizedwriteuptextforOneOtherSubTest:
		'{0} har oppnådd resultater som ligger {1} {2} {3}. {4} {5} For videre vurdering av resultatene anbefales det å lese {6}, Kapittel 5. Retningslinjer for tolkning.',
	localizedwriteuptextforTwoOtherSubTest:
		'{0} har oppnådd resultater som ligger {1} {2} {3}. {4} Ettersom {5} oppnår svake resultater på {6}, {7} og {8} anbefales derfor Dysmate oppfølgingstest. Dersom {9} på oppfølgingstesten oppnår svake resultater på Arbeidsminne og Hurtigbenevning, anbefales det å gjennomføre en utvidet kartlegging av språkforståelse.  For videre vurdering av resultatene anbefales det å lese {10}, Kapittel 5. Retningslinjer for tolkning.',
	localizedwriteuptextforThreeORMoreOtherSubTest:
		'{0} har oppnådd resultater som ligger {1} {2} {3}. {4} Ettersom {5} oppnår svake resultater på {6}, {7}, {8} {9} {10} {11}  anbefales Dysmate oppfølgingstest. For videre vurdering av resultatene anbefales det å lese {12}, Kapittel 5. Retningslinjer for tolkning.',
	localizedwriteuptextforIncompletedTest:
		'{0} har ikke fullført hele screeningtesten, og det er derfor ikke grunnlag for å komme med noen anbefalinger om videre oppfølging',
	localizedwriteuptextforLowScoreOfOrdiktatWithOneOtherSubTest:
		'Rettskrivingsvansker kan være en indikasjon på dysleksi. {0} sitt resultat på Orddiktat taler for at det gjennomføres Dysmate oppfølgingstest.',
	localizedwriteuptextforLowScoreOfBetyledeWithOneOtherSubTest:
		'Resultatet på Byttelydtesten indikerer at det bør gjøres en nærmere kartlegging av {0} fonembevissthet og fonologisk prosesseringshastighet. Det anbefales derfor å gjennomføre Dysmate oppfølgingstest.',
	localizedwriteuptextforLowScoreInLeseprovenAndOrdforstaelse:
		'{0} har oppnådd resultater som ligger {1} {2} {3}. {4} Ettersom {5} oppnår svake resultater på deltestene ordforståelse og leseprøve anbefales derfor Dysmate oppfølgingstest. For videre vurdering av resultatene anbefales det å lese {6}, Kapittel 5. Retningslinjer for tolkning.',
	testreultsforliteratescreeningtest:
		'Testresultater på Dysmate screeningtest',
	info_results_belowpresentsinformation:
		'Nedenfor presenteres testresultatene for {0}{1}{2}',
	info_report_belowpresentsinformation:
		'Nedenfor presenteres testrapport for {0}{1}{2}',
	info_user_belowpresentsinformation:
		'Nedenfor presenteres kandidatopplysninger for {0}{1}{2}',
	newinfo_belowpresentsinformation:
		'Nedenfor presenteres testresultatene for {0}{1}{2}',
	newinfo2_belowpresentsinformation:
		'Nedenfor presenteres testrapport for {0}{1}{2}',
	newinfo3_belowpresentsinformation:
		'Nedenfor presenteres kandidatopplysninger for {0}{1}{2}',
	speech1_belowpresentsinformation:
		'Nedenfor presenteres testresultatene for {0}, (Alder: {1}).',
	speech2_belowpresentsinformation:
		'Nedenfor presenteres testrapport for {0}, (Alder: {1} år).',
	infor_scr_ageAtCommencement:
		' Alder ved testdato var {0} år og {1} måneder.',
	speech3_belowpresentsinformation:
		'Nedenfor presenteres kandidatopplysninger for {0}, (Alder: {1} år).',
	info_testwascompletedon: ' Testen ble gjennomført {0}',
	info_testwasNotcompletedon: 'Denne testen er ikke komplett.',
	notetsfound: 'Ingen gjennomførte tester funnet',
	background_info_testcandidate: 'bakgrunnsinformasjon for testkandidat',
	candidate_answers: 'Kandidatens svar',
	select_users: 'Velg brukere',
	exportmailingaddress: 'Eksportér adresseliste',
	copyemails: 'Kopier e-post',
	usersupport: 'Brukerstøtte',
	groups: 'Grupper',
	creategroups: 'Lag grupper',
	createnewgroup: 'Opprett ny gruppe',
	writegroupname: 'skriv gruppenavn...',
	groupname: 'Gruppenavn',
	date: 'Dato',
	othergroups: 'Andre grupper',
	deletegroup: 'Slett gruppen',
	editgroup: 'Endre gruppen',
	insert: 'Sett inn',
	selected_users: 'Valgte Brukere',
	alreadyexistsingroup: 'finnes allerede i denne gruppen!',
	cannotaddexistingmembertogroup:
		'Kan ikke legge til medlemmer som allerede er i gruppen',
	newgroup: 'Ny gruppe',

	orddiktatheading: `Hvert ord blir først presentert i en setning for å sikre at riktig betydning av ordet er forstått. Deretter blir kandidaten bedt om å skrive det gjentatte stimulusordet. Det er ingen tidsbegrensning på denne testen, og hver setning og stimulusord kan gjentas én gang.`,
	orddiktatchildexplanation: `Det gis ett poeng for hvert riktig svar (maks 13 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	orddiktatvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,
	orddiktatadultexplanation: `Det gis ett poeng for hvert riktig svar (maks 45 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	orddiktatyouthexplanation: `Det gis ett poeng for hvert riktig svar (maks 30 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	orddiktatChildscrexplanation: `Det gis ett poeng for hvert riktig svar (maks 25 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,

	fonemisolasjonheading:
		'Denne testen kartlegger fonembevissthet hos de yngste elevene. På denne testen blir kandidaten presentert for fire bilder, og blir bedt om å peke på det bildet som enten begynner med, eller slutter på, en bestemt lyd.',
	fonemisolasjonexplanation:
		'Det gis ett poeng for hvert riktig svar (maks 16 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.',
	fonemisolasjonvariabletext: `{0} oppnådde på denne testen en skår på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,

	fonemutelatelseheading: `Denne testen kartlegger fonembevissthet. Kandidaten blir muntlig presentert for ord, og bedt om å si det ordet som blir igjen når en bestemt lyd blir tatt bort.`,
	fonemutelatelseexplanation: `Det gis ett poeng for hvert riktig svar (maks 16 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	fonemutelatelsevariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,

	bokstavkunnskapheading: `Denne testen kartlegger bokstavkunnskap hos de yngste elevene. På denne testen presenteres 15 av bokstavene i det norske alfabetet i tilfeldig rekkefølge. Kandidaten blir så bedt om å si både navnet og lyden til bokstavene. `,
	bokstavkunnskapexplanation: `Det gis ett poeng for hvert riktig svar (maks 15 poeng for lyd og maks 15 poeng for navn, til sammen maks 30 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	bokstavkunnskapvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,
	bokstavkunnskapvariabletextBH: `{0} oppnådde på denne testen en skåre på {1}. Gjennomsnitt og standardavvik for dette trinnet er ikke tilgjengelig.`,

	spoonerismheading: `Denne testen kartlegger fonembevissthet og fonologisk prosesseringshastighet. Kandidaten blir muntlig presentert for 20 ordpar, og bedt om å bytte om første lyden i de to ordene så raskt og nøyaktig som mulig.`,
	spoonerismexplanation: `Det gis ett poeng for hvert riktig ord kandidaten greier å si med den nye førstelyden (maks 40 poeng). Testen går også på tid. Skåren her er antall riktige ord som har byttet første lyd, dividert med den tiden det tar å gjennomføre oppgavene. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}. `,
	spoonerismvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,

	ettminuttstestheading: `Dette er en høytlesningsprøve som kartlegger avkodingsferdighet. Her blir kandidaten presentert for en liste med ord og bedt om å lese så fort og nøyaktig som mulig innen en tidsramme på 60 sekunder. Resultatet her gir et mål på antall riktig leste ord per minutt.`,
	ettminuttstestexplanation: `Det gis ett poeng for hvert riktig leste ord. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	ettminuttstestvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,

	hurtigbenevningheading: `Denne testen kartlegger hvor raskt kandidaten greier å benevne kjente objekter, og å gjenhente navn på objekter og farger fra det språklige langtidsminnet.`,
	hurtigbenevningexplanation: `Det gis ett poeng for hver riktig kombinasjon av farge og objekt kandidaten sier. Testen går også på tid. Skåren her er antall riktige farge-objekt-kombinasjoner dividert med den totale tiden det tar å benevne alle 50 farge-objekt-kombinasjonene. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	hurtigbenevningvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,
	hurtigbenevningBHexplanation: `Det gis ett poeng for hver riktig kombinasjon av farge og objekt kandidaten sier. Denne testen går også på tid. Skåren her er antall riktige farge-objekt-kombinasjoner dividert med den totale tiden det tar å benevne alle 25 farge-objekt-kombinasjonene. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,

	arbeidsminnenummberheading: `Denne testen kartlegger testkandidatens evne til å lagre og bearbeide informasjon. På denne testen blir kandidaten først presentert for en serie med tall, deretter en serie med ord, som skal gjentas i omvendt rekkefølge enn de blir presentert. Både tallrekkene og ordrekkene øker i lengde dersom kandidaten mestrer den forrige rekken.`,
	arbeidsminneexplanation: `Det gis ett poeng for hvert riktig svar (maks 12 poeng for tallrekkene og maks 12 poeng for ordrekkene, til sammen maks 24 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	arbeidsminnevariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,
	arbeidsminneheading: `Denne testen kartlegger kandidatens evne til å lagre og bearbeide informasjon, mens man løser en oppgave. Kandidaten blir først presentert for en serie med tall, deretter en serie med ord, som skal gjentas i omvendt rekkefølge enn de blir presentert. Både tallrekkene og ordrekkene øker i lengde.`,

	ordforståelseheading: `Testen måler ordforråd ved at kandidaten må finne et ord som har lik betydning som det gitte stimulusordet. På hver oppgave må kandidaten velge blant fire alternativer. Denne testen gir et mål på bredde og dybde av ordforrådet.`,
	ordforståelseexplanation: `Det gis ett poeng for hvert riktig svar (maks 15 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	ordforståelsevariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,
	ordforståelseyouthexplanation: `Det gis ett poeng for hvert riktig svar (maks 20 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	ordforståelsechildexplanation: `Det gis ett poeng for hvert riktig svar (maks 18 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	leseprøvenheading: `Denne testen er en stillelesingsprøve som måler leseforståelse ved at kandidaten må svare på spørsmål direkte fra teksten og sammenstille informasjon fra ulike avsnitt. Kandidaten får 10 minutter til å lese teksten og svare på så mange flervalgsspørsmål som mulig. NB! I tolkningsarbeidet er det viktig å undersøke hvorvidt testkandidaten har svart feil eller ikke har besvart flervalgsspørsmålene (på grunn av lav lesehastighet).`,
	leseprøvenyouthheading: `Denne testen er en stillelesingsprøve som måler leseforståelse ved at kandidaten må svare på spørsmål direkte fra teksten, sammenstille informasjon fra ulike avsnitt og trekke konklusjoner. Kandidaten får 12 minutter til å lese teksten og svare på så mange flervalgsspørsmål som mulig. NB! Her er det viktig å registrere hvorvidt testkandidaten har svart feil eller ikke har besvart flervalgsspørsmålene (på grunn av sein lesehastighet).`,
	leseprøvenexplanation: `Det gis ett poeng for hvert riktig svar (maks 14 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	leseprøvenvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,
	leseprøvenyouthexplanation: `Det gis ett poeng for hvert riktig svar (maks 9 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	leseprøvenchildheading: `Denne testen er en stillelesingsprøve som måler leseforståelse ved at kandidaten må svare på spørsmål direkte fra teksten, sammenstille informasjon fra ulike avsnitt og trekke konklusjoner. Kandidaten får 10 minutter til å lese teksten og svare på så mange flervalgsspørsmål som mulig. NB! Her er det viktig å registrere hvorvidt testkandidaten har svart feil eller ikke har besvart flervalgsspørsmålene (på grunn av sein lesehastighet).`,
	leseprøvenchildexplanation: `Det gis ett poeng for hvert riktig svar (maks 8 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,

	skrivehastighetheading: `Denne testen måler effektivitet i skriving. Det er en ferdighet som behøves når man for eksempel skal ta notater eller skrive en eksamensbesvarelse. Kandidaten får se en setning foran seg hele tiden, og skal skrive av denne så mange ganger og så nøyaktig som mulig i løpet 2 minutter. `,
	skrivehastighetexplanation: `Skåren her er antall riktig skrevne ord i snitt per minutt. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	skrivehastighetvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,

	tulleordtestenheading: `Denne testen er en stillelesingsprøve som måler fonologisk bevissthet og fonologisk prosesseringshastighet. Dette regnes som en språklig-kognitiv markør på dysleksi. Testkandidaten skal finne det tulleordet som 𝘩ø𝘳𝘦𝘴 𝘶𝘵 som et ordentlig ord. På hver oppgave må testkandidaten velge blant fem alternativer, hvor bare ett er riktig. Testkandidaten får to minutter til å løse så mange oppgaver som mulig.`,
	tulleordtestenexplanation: `Det gis ett poeng for hvert riktig svar (maks 25 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	tulleordtestenvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,

	byttelydtestheading: `Denne testen måler fonembevissthet og fonologisk prosesseringshastighet, som er en språklig-kognitiv markør på dysleksi. 
	Testen går ut på at kandidaten skal bytte ut en gitt lyd i ord med en annen lyd, 
	sånn at det blir et nytt ord. For eksempel blir kandidaten bedt om å bytte ut 
	lyden /b/ i «bil» med lyden /p/. På hver oppgave får kandidaten presentert en 
	illustrasjon av hovedordet (f.eks. bil), som så blir etterfulgt av fire illustrerte 
	svaralternativ. Bare ett av alternativene er riktig (her: pil). Kandidaten får 10 
	sekunder til å løse hver oppgave. En ny oppgave dukker automatisk opp på 
	skjermen dersom kandidaten ikke har svart innen tidsfristen.`,
	byttelydtestexplanation: `Det gis ett poeng for hvert riktig svar (maks 21). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	byttelydtestvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,
	ordkjedetestenheading: `Denne testen er en stillelesingsprøve som måler hurtig ordgjenkjenning og ortografisk kunnskap. Her blir eleven bedt om å lage mellomrom mellom ord som er skrevet sammen til en kjede. Hver kjede består av fire tilfeldige ord. Testkandidaten får 4 minutter til å løse så mange ordkjeder som mulig.`,
	ordkjedetestenexplanation: `Det gis ett poeng for hvert riktig svar (maks 40 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	ordkjedetestenvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. {3} oppnår dermed et resultat som plasserer seg`,
	ordkjedetestenyouthexplanation: `Det gis ett poeng for hvert riktig svar (maks 36 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,

	averageForGrade: 'gjennomsnittsområdet for trinnet.',
	warning_blockuser: 'Er du sikker på at du vil blokkere valgte bruker?',
	warning_unblockuser: 'Er du sikker på at du vil ublokkere valgte bruker?',
	//NewTestModal
	choosegroup: 'Velg gruppe',
	includeLongIntro: 'Inkluder selvrapportering /anamnese',
	fillSelfCandidateInfo: 'Fyll ut informasjon om kandidaten selv',
	createanddistributetests:
		'Ønsker du å sende mange tester samtidig, kan du lime inn e-post adresser i feltet her og trykke "Opprett og send".',
	remembertoprovideemail: 'Husk at epostene  må skilles med komma',
	exmapledemail: 'ola@epost.no, eva@epost.no',
	letCandidateFillInfo: 'La kandidaten fylle ut informasjon selv',
	anamneseexplaination:
		'Dette er en endring innført 22.april. Skal testen brukes som en screening, så er det ikke nødvendig å ha med selvrapportering/anamnese',

	fillKandidateInformation1:
		'Testadministrator (lærer) fyller ut informasjon om kandidaten før eleven logger inn for å ta testen. Kandidaten kommer raskt i gang med testen og kan logge inn med pinkode',
	fillKandidateInformationSelf1:
		'Dette gjør det mulig å sende ut testen direkte på e-post til kandidaten. Kandidaten må logge inn med Feide og fylle ut informasjon om seg selv (Navn, kjønn, fødselsdato og klassetrinn)',
	fillKandidateInformationSelf2:
		'Dette passer typisk for elever på mellomtrinnet som har tilgang til både egen e-post og Feide,  og som skal ta leseprøven.',
	candidateInfoCommon:
		'Test kandidaten tar testen på dysmate.no > ta testen.',
	candidateInfoReminder:
		'Merk at pinkoden også er tilgjengelig for testadministrator i oversikten på <<Mine tester>>',
	emailReason: 'E-posten brukes til å sende ut pinkoden til pålogging',
	chooselanguage: 'Velg språk',
	//Groups
	warning_deletegroup: 'Er du sikker på at du vil slette denne gruppen?',
	warning_deletemember: 'Er du sikker på at du vil slette dette medlemmet?',
	alltests: 'Alle tester',
	seegrouptests: 'Se gruppetester',
	addtogroup: 'Legg til i en gruppe',
	nogroupsfound: 'Ingen grupper funnet',
	groupalreadyexists: 'Gruppen eksisterer allerede',
	fromgroups: 'Fra gruppe',
	allmytests: 'Alle mine tester',
	groupindicator: 'Grupper',
	//UserStageEditor
	approveuesr: 'Godkjenn bruker',
	sendnewinvitation: 'Send invitasjon på nytt',
	trialperiodexpired: 'Prøveperiode utløpt',
	trialperiodinprogress: 'Prøveperiode pågår',
	extendtrialperiod: 'Utvid prøveperiode',
	message_notallowedemptysubscription:
		'Du kan ikke tildele et tomt abonnement! Slett språket i stedet.',
	savepermission: 'save permission',
	addlanguage: 'Legg Til Språk',
	warning_deleteLanguage: 'Er du sikker på at du vil slette dette språken?',
	//subtestAnswers
	notanswered: 'ikke besvart',
	testnotanswered: '{0} oppgaver ikke besvart',
	showunanswered: 'Vis ubesvarte',
	hideunanswered: 'Skjul ubesvarte',
	rightanswer: 'Rett svar: {0}',
	checked: 'checked',
	//DumpTests
	testdeletedbyuser: 'Testen ble slettet av brukeren',
	testolderthan3years: 'testen er eldre enn 3 år',
	usedon: 'Brukt på',
	reason: 'Årsaken',
	action: 'Handling',
	warning_deleteSingleTest:
		'Dette vil slette testen permanent. Fortsett uansett?',
	warning_deleteAllTest:
		'Dette vil slette alle valgte testene permanent. Fortsett uansett?',
	deleteall: 'Slett alle',
	//Myclasses
	chooseclass: 'Velg klasse',
	confirmationdeleteclass: 'Er du sikker på at du vil slette denne {0}',
	theclass: 'klassen',
	multipleclasses: 'klasser',
	deleteclass: 'Slett klasse',
	message_noclasses: 'Ingen klasser',
	message_noTestsFound: 'Du har ingen slettede tester',
	//My candidates
	showtests: 'Se tester ▼',
	allmycandidates: 'Alle mine kandidater',
	notestsforcandidate: 'Denne kandidaten har ingen gjennomførte tester',
	gender: 'Kjønn',
	nocandidates: 'Du har ingen testkandidater',
	choosecandidate: 'Velg kandidat',
	classindicator: 'Kandidater i Klasser:',
	sendtest: 'Send test',
	createTest: 'Opprett Test',
	createclass: 'Opprett Klasse',
	createdAt: 'Opprettet kl',
	createdOn: 'Opprettet kl',
	municipalityName: 'Kommune',
	municipalityNumber: 'Org nr',
	removefromclass: 'Fjern fra Klasse',
	providevalidname: 'Oppgi et gyldig navn',
	searchhere: 'Søk her...',
	searchByTestKey: 'Søk Testnøkkel',
	searchFeide: 'Feide 2.0',
	viewMunicipality: 'Se Kommune',
	addMunicipality: 'Legg til kommune',
	createNotification: 'Opprett et varsling',
	viewNotification: 'Se varslinger',
	postNotification: 'Post Notification',
	areyousure: 'Er du sikker?',
	editNotification: 'Rediger Varsling',
	notificationcreated: 'Varsel opprettet',
	municipalityAdded: 'Kommune lagt til',
	municipalityUpdated: 'Kommunen ble oppdatert!',
	allnotifications: 'Alle varslinger',
	nonotificationsfound: 'Ingen varslinger funnet',
	noMunicipalityFound: 'Ingen kommune funnet',
	createnotificationwarning:
		'Du kan ikke endre varselet når det først er lagt ut',
	//newcalssmodal
	newclass: 'Ny Klasse',
	writeclassname: 'Skriv klassenavn...',
	deleting: 'Sletter',
	//AddTestToGroup
	choose: 'Velg',
	chooseagroup: 'Velg en gruppe',
	removefromgroup: 'Fjern fra grupper',
	//testdispatchmodal
	warning_candidatesfromdifferentcategori:
		'Noen av kandidatene har tatt tester tidligere som tilhørte en annen kategori. Fortsett uansett?',
	mypage: 'Min side',
	//testadminsummary
	nonewusersduringtimeperiod:
		'Ingen nye kandidater i løpet av denne tidsperioden',
	//subtestReport
	notavailable: 'ikke tilgjengelig',
	theagegroup: 'aldersgruppen',
	hiddenname: '<Skjult navn>',
	wordreadcorrectly: 'riktige ord lest',
	correct: 'Riktig',
	wrong: 'Feil',
	timeused: 'Tid brukt',
	notestsafterdatetoinvoice: 'Ingen tester etter valgte dato å fakturere',
	//
	reminder_feideregistration:
		'Hvis du registrerer deg med FEIDE, må du også bruke FEIDE for å logge inn til tjenesten videre. Vil du fortsette?',
	reminder_bankidregistration:
		'Vi anbefaler at du registrer deg selv med Feide. Hvis du registrerer deg med Bank-ID, må du også bruke Bank-ID for å logge inn til tjenesten videre.  Vil du fortsette uansett?',
	reminder_bankidmobileregistration:
		'Vi anbefaler at du registrer deg selv med Feide. Hvis du registrerer deg med Bank-ID på Mobil, må du også bruke Bank-ID på Mobil for å logge inn til tjenesten videre.  Vil du fortsette uansett?',
	reminderScoreChange:
		'Før du endrer statusen til dette bestemte svaret, vil vi minne deg om at dette vil påvirke kandidatens poengsum',
	correctAnswerConfirmation:
		'Vennligst bekreft hvorvidt ordet var lest riktig eller feil',
	feiderecommended: 'Feide (anbefalt)',
	thisagegroup: 'aldersgruppen',
	cantretrieveaudio: 'Vi kunne ikke finne lydfiler for denne testen',
	adminpersonalinformation: 'Personopplysninger',
	changeToFeide: 'Ja, jeg ønsker å bytte til Feide ',
	changeToFeide2: 'Ja, jeg ønsker å bytte til Feide 2.0 ',
	chageToPassword: 'Ja, jeg ønsker å bytte til Passord ',
	testredistributed:
		'Testen er sendt til kandidaten. Ny pinkode er {0} (Hvis kandidaten skal logge inn med e-post)',
	testCandidateTakeTestWith: 'Testkandidatene/elevene tar testen ved å',
	gotoDysmtateSite: 'Gå til dysmate.no',
	testCommenceInstructions1:
		'Velge «ta testen» og velge «Screeningtest for barn»',
	testCommenceInstructions2: 'Skrive inn testnøkkelen',
	testCommenceInstructions3: 'Logge inn med Feide eller Pinkode.',
	testCommenceInstructions4:
		'E-post er valgfritt. Hvis dette feltet er tomt må du selv fortelle testkandidaten/eleven pinkoden.',
	testCommenceInstructions5:
		'Hvis du skriver inn en e-post adresse, så sendes både testnøkkelen og pinkode til e-postadressen',
	question_excludeReadingTest:
		'Vil du utelukke deltesten leseprøve? Denne kan ekskluderes for barn som ikke kan lese',
	fillOutInfo: 'Fyll ut Informasjon',
	homeLanugage: 'Hjemmespråk',

	printInformation: 'Print opplysninger',
	highestEducation: 'Høyeste fullførte utdanning:',
	otherCompetence: 'Annen utdanning:',
	goesToHighSchool: 'Går på videregående skole: ',

	warning2: 'Advarsel 2',
	warning2text:
		' - Det ser ut til at du har deaktivert sporing på tvers av nettsteder i nettleseren din. Dette er nødvendig for å bruke Dysmate dysleksitester. ',
	linkForInstructions: ' Her',

	warning_text_end: ' er en oppskrift for hvordan du skrur dette på.',
	//Enrollment
	message_first_login: `Hvis du aldri har logget inn før på {0}, så må du fullføre din registrering.`,
	message_initial_approval: `Vi har godkjent deg basert på den eposten du oppga når du tok sertifiseringskurset.`,
	message_provide_email: `Tast inn denne eposten i feltet under, så sender vi deg registrerings lenken.`,
	info_registration_eamil_sent: `Flott! Vi har nå sendt deg en registreringslenke på e-posten du oppga.`,
	message_not_yet_approved: `Vi finner ingen godkjente testadministratorer med den e-posten som du oppga. Dette kan skyldes at vi ikke har godkjent deg som testadministrator enda. Dette tar typisk 1-3 dager fra man melder seg på kurs.`,
	message_user_not_found: `Vi finner ingen kursdeltagere med den e-posten som du oppga. Dette kan skyldes at du brukte en annen e-post når du registrerte deg for sertifiseringskurset.`,
	message_user_already_registered: `Det ser ut som om du allerede har fullført registreringen din.  Logg inn med {0} {1}.`,
	message_trial_period_expired: `Din prøveperiode er utløpt. Ta kontakt med oss for å tegne et abonnement.`,
	message_try_loggin_in: `Det ser ut som om du allerede har fullført registreringen din.  Logg inn {0}.`,
	no_tests_available_message: 'Du har ingen tester.',
};
